'use strict';

(function ($, window, document, undefined) {
  const pluginName = 'bsAlert';
  const methods = {
    remove: function () {
      const alert = $(this).children('.alert').first();
      const pluginSettings = alert.data(pluginName);
      return alert.length !== 0 ? alert.fadeOut('fast', function () {
        alert.remove();

        // Callback
        pluginSettings.onDismiss();
      }) : undefined;
    }
  };
  class Plugin {
    constructor(element, options) {
      this.element = $(element);
      this.settings = $.extend(true, {}, $.fn[pluginName].defaults, options);
      this._init();
    }
  }
  $.extend(Plugin.prototype, {
    _init: function () {
      this._require();
      this._buildAlert();
    },
    _require: function () {
      if (!this.settings.type) {
        console.error(`No type specified for ${pluginName}.`);
      }
      if (!this.settings.content && !this.settings.heading) {
        console.error(`No content provided for ${pluginName}.`);
      }
    },
    _buildAlert: function () {
      // Alert
      const alert = $('<div>').attr('role', 'alert').addClass(`alert ${this.settings.type}`);

      // Icon
      let icon;
      if (this.settings.icon) {
        icon = $('<i>').addClass(`${this.settings.icon} me-2`);
      }

      // Heading
      if (this.settings.heading) {
        const heading = $('<h4>').addClass('alert-heading').append(this.settings.heading);

        // Heading icon
        if (this.settings.icon && !this.settings.content) {
          heading.prepend(icon);
        }
        alert.append(heading);
        if (this.settings.content) {
          alert.append('<hr>');
        } else {
          alert.find('h4').addClass('mb-0');
        }
      }

      // Content
      let content;
      if (this.settings.heading) {
        content = $('<p>').addClass('mb-0').append(this.settings.content);
      } else {
        content = this.settings.content;
      }

      // Content icon
      if (this.settings.icon) {
        if (this.settings.heading && this.settings.content) {
          content.prepend(icon);
        } else {
          content = icon.prop('outerHTML') + content;
        }
      }
      if (this.settings.content) {
        alert.append(content);
      }

      // Content anchors
      alert.find('a').addClass('alert-link');

      // Dismiss button
      if (this.settings.dismissible) {
        const button = $('<button>').attr({
          type: 'button',
          'data-bs-dismiss': 'alert',
          'aria-label': 'Dismiss',
          title: 'Dismiss'
        }).addClass('btn-close');
        alert.append(button);
        alert.addClass('alert-dismissible fade show');
        const _this = this;
        button.click(function () {
          // Callback
          if (!_this.settings.fadeout) {
            _this.settings.onDismiss();
          }
        });
      }

      // Render
      alert.data(pluginName, this.settings);
      let render = alert.hide().fadeIn();

      // Duration/automatic fadeout
      if (this.settings.fadeout) {
        const duration = this.settings.duration * 1000;
        const _this = this;
        render = render.delay(duration).fadeOut(function () {
          // Callback
          _this.settings.onDismiss();
          render.remove();
        });
      }
      if (this.settings.clear) {
        this.element.empty();
      }
      this.element.prepend(render);

      // Callback
      this.settings.onShow();
    }
  });
  $.fn[pluginName] = function (config) {
    if (methods[config]) {
      return methods[config].apply(this, [].slice.call(arguments, 1));
    } else if (typeof config === 'object' || !config) {
      return this.each(function () {
        new Plugin(this, config);
      });
    } else {
      console.error(`${pluginName}: method "${config}" is unavailable.`);
    }
  };
  $.fn[pluginName].defaults = {
    // Attributes
    type: null,
    dismissible: true,
    icon: null,
    heading: null,
    content: null,
    fadeout: true,
    duration: 5,
    clear: true,
    // Callbacks
    onShow: function () {},
    onDismiss: function () {}
  };
})(jQuery, window, document);